// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-empresa-js": () => import("./../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-solucoes-js": () => import("./../../src/pages/solucoes.js" /* webpackChunkName: "component---src-pages-solucoes-js" */),
  "component---src-pages-sustentabilidade-js": () => import("./../../src/pages/sustentabilidade.js" /* webpackChunkName: "component---src-pages-sustentabilidade-js" */),
  "component---src-pages-termo-de-consentimento-js": () => import("./../../src/pages/termo-de-consentimento.js" /* webpackChunkName: "component---src-pages-termo-de-consentimento-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */),
  "component---src-templates-solution-template-js": () => import("./../../src/templates/solutionTemplate.js" /* webpackChunkName: "component---src-templates-solution-template-js" */)
}

